import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import scrollTo from 'gatsby-plugin-smoothscroll';


import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  FiCode,
} from 'react-icons/fi';
import {
  MdBuild,
  MdSettingsInputComposite,
  MdArtTrack,
  MdVideocam,
  MdContactPhone,
  MdWifi,
  MdFormatQuote,
  MdLocalOffer,
  MdLocationOn,
  MdPhone,
  MdEmail,
  MdPublic,
  MdStar
} from 'react-icons/md';

import {RiFacebookLine,
        RiLinkedinLine,
        RiInstagramLine
} from 'react-icons/ri';

/**
 * COMENTAR SI SE ESTA DESARROLLO
 */
import OwlCarousel from './../js/OwlCarousel';

/**
 * COMENTAR SI SE VA HACER BUILD
 */
// import OwlCarousel from 'react-owl-carousel';

// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';


const IndexPage = () => {
  const data = useStaticQuery(graphql`
          query {
            image1: file(relativePath: { eq: "bg_2.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1500) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            bg_3: file(relativePath: { eq: "bg_3.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1500) {
                      ...GatsbyImageSharpFluid
                  }
              }
            }
            redes: file(relativePath: { eq: "redes.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1500) {
                      ...GatsbyImageSharpFluid
                  }
              }
            }
            soporte: file(relativePath: { eq: "soporte.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1500) {
                      ...GatsbyImageSharpFluid
                  }
              }
            }
            project_4: file(relativePath: { eq: "project-4.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 350) {
                      ...GatsbyImageSharpFluid
                  }
              }
            }
            project_5: file(relativePath: { eq: "project-5.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 350) {
                      ...GatsbyImageSharpFluid
                  }
              }
            }
            about: file(relativePath: { eq: "about.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1500) {
                      ...GatsbyImageSharpFluid
                  }
              }
            }
            no_image: file(relativePath: { eq: "no_image.png" }) {
              childImageSharp {
                  fluid(maxWidth: 300) {
                      ...GatsbyImageSharpFluid
                  }
              }
            }

            cartuchos: file(relativePath: { eq: "cartuchos.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1500) {
                      ...GatsbyImageSharpFluid
                  }
              }
            }
            laptop: file(relativePath: { eq: "laptop.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1500) {
                      ...GatsbyImageSharpFluid
                  }
              }
            }
            escritorio_dev: file(relativePath: { eq: "escritorio_dev.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1500) {
                      ...GatsbyImageSharpFluid
                  }
              }
            }
            camara: file(relativePath: { eq: "camara.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1500) {
                      ...GatsbyImageSharpFluid
                  }
              }
            }
            cableCoa: file(relativePath: { eq: "cableCoa.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1500) {
                      ...GatsbyImageSharpFluid
                  }
              }
            }
            tienda: file(relativePath: { eq: "tienda.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1500) {
                      ...GatsbyImageSharpFluid
                  }
              }
            }
            maxiprint: file(relativePath: { eq: "maxiprint.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1500) {
                      ...GatsbyImageSharpFluid
                  }
              }
            }
            magnaprint: file(relativePath: { eq: "magnaprint.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1500) {
                      ...GatsbyImageSharpFluid
                  }
              }
            }
            toner_samsung: file(relativePath: { eq: "samsung_toner.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1500) {
                      ...GatsbyImageSharpFluid
                  }
              }
            }
            miguel: file(relativePath: { eq: "miguel.png" }) {
              childImageSharp {
                  fluid(maxWidth: 1500) {
                      ...GatsbyImageSharpFluid
                  }
              }
            }
            tahrca: file(relativePath: { eq: "tahrca2.png" }) {
              childImageSharp {
                  fluid(maxWidth: 1500) {
                      ...GatsbyImageSharpFluid
                  }
              }
            }
            fullcolor: file(relativePath: { eq: "fullcolor1.png" }) {
              childImageSharp {
                  fluid(maxWidth: 1500) {
                      ...GatsbyImageSharpFluid
                  }
              }
            }
            vargas: file(relativePath: { eq: "vargas1.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 500) {
                      ...GatsbyImageSharpFluid
                  }
              }
            }
            colmenares: file(relativePath: { eq: "colmenares.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 800) {
                      ...GatsbyImageSharpFluid
                  }
              }
            }
            gomes: file(relativePath: { eq: "gomes.jpeg" }) {
              childImageSharp {
                  fluid(maxWidth: 800) {
                      ...GatsbyImageSharpFluid
                  }
              }
            }
            manuel: file(relativePath: { eq: "manuel.jpeg" }) {
              childImageSharp {
                  fluid(maxWidth: 800) {
                      ...GatsbyImageSharpFluid
                  }
              }
            }
            hp: file(relativePath: { eq: "hp.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            intel: file(relativePath: { eq: "Intel.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            samsung: file(relativePath: { eq: "Samsung.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            nvidia: file(relativePath: { eq: "NVidia.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            tplink: file(relativePath: { eq: "tplink.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            lg: file(relativePath: { eq: "LG.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            acer: file(relativePath: { eq: "Acer.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            ubiquiti: file(relativePath: { eq: "Ubiquiti.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            TrippLite: file(relativePath: { eq: "TrippLite.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            Xerox: file(relativePath: { eq: "Xerox.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            Dahua: file(relativePath: { eq: "Dahua.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            AMD: file(relativePath: { eq: "AMD.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            AOC: file(relativePath: { eq: "AOC.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            Grandstream: file(relativePath: { eq: "Grandstream.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            Hikvision: file(relativePath: { eq: "Hikvision.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            Iomega: file(relativePath: { eq: "Iomega.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            Mikrotik: file(relativePath: { eq: "Mikrotik.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            Sandisk: file(relativePath: { eq: "Sandisk.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            Toshiba: file(relativePath: { eq: "Toshiba.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            Compaq: file(relativePath: { eq: "Compaq.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            Epson: file(relativePath: { eq: "Epson.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            mikrotik_prod: file(relativePath: { eq: "mikrotik_prod.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            tp_link: file(relativePath: { eq: "tp_link.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }

          }
      `
    )
    let numCard = 3

    if( typeof window !== `undefined`){

        if( window.screen.width <= 480 ){
            numCard = 1

        }else if (window.screen.width <= 768 ){
            numCard = 3

        }
    }
  return (

    <Layout changeHeader={1}>
      <SEO title="Inicio" />


      <section id="home-section" className="hero">
        <div className="home-slider js-fullheight owl-carousel">
        <h3 className="vr">Bienvenido a Metalinks</h3>
          <OwlCarousel
            className="owl-theme"
            loop={true}
            margin={0}
            nav={true}
            items={1}
            autoplay={true}
            mouseDrag={false}
            autoplayHoverPause={true}
          >


          <div className="slider-item js-fullheight">
            <div className="overlay"></div>
            <div className="container-fluid p-0">
              <div className="row d-md-flex no-gutters slider-text js-fullheight align-items-center justify-content-end" data-scrollax-parent="true">
                <div className="one-third order-md-last img js-fullheight" style={{backgroundImage: `url(${data.bg_3.childImageSharp.fluid.src})`, height: '100%'}}>
                  <div className="overlay"></div>
                </div>
                <div className="one-forth d-flex js-fullheight align-items-center" data-scrollax=" properties: { translateY: '70%' }">
                  <div className="text" data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease">
                    <span className="subheading">¡Creando Enlaces Estables!</span>
                    <h1 className="mb-4 mt-3"><span>Agencia</span> de  Desarrollo de <span>Software</span></h1>
                    <p className="text-justify">Ofrecemos las mejores soluciones tecnológicas según las necesidades de nuestros clientes.</p>

                    <p><a href={'javascript:void(0);'}  onClick={() => scrollTo('#services-section')} className="btn btn-primary px-5 py-3 mt-3">Ver más</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="slider-item js-fullheight">
            <div className="overlay"></div>
            <div className="container-fluid p-0">
              <div className="row d-flex no-gutters slider-text js-fullheight align-items-center justify-content-end" data-scrollax-parent="true">
                <div className="one-third order-md-last img js-fullheight" style={{backgroundImage: `url(${data.soporte.childImageSharp.fluid.src})`, height: '100%'}}>
                  <div className="overlay"></div>
                </div>
                <div className="one-forth d-flex js-fullheight align-items-center" data-scrollax=" properties: { translateY: '70%' }">
                  <div className="text" data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease">
                    <span className="subheading">¡Creando Enlaces Estables!</span>
                    <h1 className="mb-4 mt-3"><span>Soporte </span> Técnico y <span>Mantenimiento</span> </h1>
                    <p className="text-justify">Ofrecemos el mejor servicio de asistencia técnica en el área de infraestructura tecnológica.</p>

                    <p><a href={'javascript:void(0);'}  onClick={() => scrollTo('#services-section')} className="btn btn-primary px-5 py-3 mt-3">Ver más</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        <div className="slider-item js-fullheight">
          <div className="overlay"></div>
            <div className="container-fluid p-0">
              <div className="row d-flex no-gutters slider-text js-fullheight align-items-center justify-content-end"       data-scrollax-parent="true">
                <div className="one-third order-md-last img js-fullheight" style={{backgroundImage: `url(${data.redes.childImageSharp.fluid.src})`, height: '100%'}}>
                  <div className="overlay"></div>
                </div>
                <div className="one-forth d-flex js-fullheight align-items-center" data-scrollax=" properties: { translateY: '70%' }">
                <div className="text" data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease">
                  <span className="subheading">¡Creando Enlaces Estables!</span>
                  <h1 className="mb-4 mt-3"><span>Soporte</span> de <span>Redes</span> Informáticas</h1>
                  <p className="text-justify">Nos especializamos en bridar servicio de calidad para soporte de redes, utilizando las mejores herramientas del mercado.</p>

                  <p><a href={'javascript:void(0);'}  onClick={() => scrollTo('#services-section')}  className="btn btn-primary px-5 py-3 mt-3">Ver más</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>

        </OwlCarousel>
        </div>


      </section>

      <section className="ftco-section ftco-no-pb ftco-no-pt ftco-services bg-light mt-5" id="services-section">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-md-4 py-5 mt-5 nav-link-wrap" data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease">
              <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                <a className="nav-link px-3 active" id="v-pills-1-tab" data-toggle="pill" href="#v-pills-1" role="tab" aria-controls="v-pills-1" aria-selected="true"><FiCode color="#f5f5f5" size="1.3em" /> Desarrollo de Software</a>

                <a className="nav-link px-3" id="v-pills-2-tab" data-toggle="pill" href="#v-pills-2" role="tab" aria-controls="v-pills-2" aria-selected="false"><MdBuild color="#f5f5f5" size="1.3em" /> Soporte Técnico</a>

                <a className="nav-link px-3" id="v-pills-3-tab" data-toggle="pill" href="#v-pills-3" role="tab" aria-controls="v-pills-3" aria-selected="false"><MdSettingsInputComposite color="#f5f5f5" size="1.3em" /> Soporte de redes Informática</a>

                <a className="nav-link px-3" id="v-pills-4-tab" data-toggle="pill" href="#v-pills-4" role="tab" aria-controls="v-pills-4" aria-selected="false"><MdArtTrack color="#f5f5f5" size="1.5em" /> Marketing Digital</a>

                <a className="nav-link px-3" id="v-pills-4-tab" data-toggle="pill" href="#v-pills-7" role="tab" aria-controls="v-pills-4" aria-selected="false"><MdVideocam color="#f5f5f5" size="1.3em" /> Seguridad CCTV</a>

                <a className="nav-link px-3" id="v-pills-5-tab" data-toggle="pill" href="#v-pills-5" role="tab" aria-controls="v-pills-5" aria-selected="false"><MdContactPhone color="#f5f5f5" size="1.3em" /> Consultoría Tecnológica</a>

                <a className="nav-link px-3" id="v-pills-6-tab" data-toggle="pill" href="#v-pills-6" role="tab" aria-controls="v-pills-6" aria-selected="false"><MdWifi color="#f5f5f5" size="1.3em" /> Proveedor WISP</a>
              </div>
            </div>
            <div className="col-md-8 p-4 p-md-5 d-flex align-items-center" data-sal="slide-left" data-sal-duration="1500" data-sal-easing="ease">

              <div className="tab-content pl-md-1" id="v-pills-tabContent">

                <div className="tab-pane fade show active py-5 text-justify" id="v-pills-1" role="tabpanel" aria-labelledby="v-pills-1-tab">
                  <FiCode color="#0075c7" className="mb-3 d-block" size="4.5em" />
                  <h2 className="mb-4">Desarrollo de Software</h2>
                  <p>Hacemos más eficiente los procesos de su empresa. Aumente su productividad con sistemas que se ajusten a los procesos, en <b>Metalinks C.A</b> ofrecemos:</p>

                  <div className="row">
                    <div className="col-md-4">
                        1. Landing page. <br/>
                        2. Página Corporativa.<br/>

                      </div>
                      <div className="col-md-6">
                        3. Blog.<br/>
                        4. Desarrollo de software a la medida.
                    </div>
                  </div>

                  <p><Link to="/software-developer" className="btn btn-primary px-4 py-3 mt-3">Ver más</Link></p>

                </div>

                <div className="tab-pane fade py-5 text-justify" id="v-pills-2" role="tabpanel" aria-labelledby="v-pills-2-tab">
                  <MdBuild color="#0075c7" className="mb-3 d-block" size="4.5em" />
                  <h2 className="mb-4">Soporte y Mantenimiento Técnico</h2>
                <p>En <b>Metalinks C.A</b> Ofrecemos el mejor servicio de asistencia técnica corporativa en el área de infraestructura tecnológica a empresas u organismos, al contratar nuestros servicios usted recibirá soluciones efectivas y eficaces a los problemas que surgen constantemente en el ámbito tecnológico.</p>

                  <div className="row">
                    <div className="col-md-6">
                        1. Consultoría de software y hardware.<br/>
                        2. Optimización de recursos.<br/>
                        3. Soporte remoto y presencial

                      </div>
                      <div className="col-md-6">
                        4. Administración de redes y servidores multiplataforma.<br/>
                        5. Mantenimiento programado.<br/>
                        6. Mantenimiento Físico y/o Lógico.
                    </div>
                  </div>

                          <p><Link to="/support-tec" className="btn btn-primary px-4 py-3 mt-3">Ver más</Link></p>
                        </div>

                        <div className="tab-pane fade py-5 text-justify" id="v-pills-3" role="tabpanel" aria-labelledby="v-pills-3-tab">
                          <MdSettingsInputComposite color="#0075c7" className="mb-3 d-block" size="4.5em" />
                          <h2 className="mb-4">Soporte en redes Informática</h2>
                  <p> Nos especializamos en brindar el mejor servicio en Soporte de Redes Informáticas, con un equipo especializado y capacitado, utilizando las mejores herramientas para brindar la mejor atención personalizada enfocándonos en las necesidades de nuestros clientes.</p>
                  <div className="row">
                    <div className="col-md-6">
                        1. Diseño e Instalación de Red.<br/>
                        2. Configuración de Red Cableadas e Inalámbricas<br/>
                        3. Voz, Datos y Videos.

                      </div>
                      <div className="col-md-6">
                        4. Voz sobre IP.<br/>
                        5. Almacenamiento y Respaldos Locales.<br/>
                        6.Almacenamiento y Respaldos en Nubes.
                    </div>
                  </div>


                  <p><Link to="/support-networks" className="btn btn-primary px-4 py-3 mt-3">Ver más</Link></p>
                </div>

                <div className="tab-pane fade py-5 text-justify" id="v-pills-4" role="tabpanel" aria-labelledby="v-pills-4-tab">
                  <MdArtTrack color="#0075c7" className="mb-3 d-block" size="5.5em" />
                  <h2 className="mb-4">Marketing digital</h2>
                  <p>En <b>Metalinks C.A</b> ofrecemos un servicio de marketing digital que incluye la gestión de redes sociales, sabemos lo importante que son las publicaciones que dan vida a tu negocio, haciendo que tengas presencia en internet y un acercamiento a tus clientes, donde puedes publicar novedades de tu empresa, cosas de interés, ofertas, descuentos, hacer promociones, entre otros.</p>

                  <p><Link to="/marketing-digital" className="btn btn-primary px-4 py-3 mt-3">Ver más</Link></p>
                </div>

                <div className="tab-pane fade py-5 text-justify" id="v-pills-7" role="tabpanel" aria-labelledby="v-pills-7-tab">
                  <MdVideocam color="#0075c7" className="mb-3 d-block" size="4.5em" />
                  <h2 className="mb-4">Seguridad CCTV</h2>
                  <p>Queremos que te sientas seguro y protegido contra factores fuera de tu control para eso contamos con un personal altamente calificado en el desarrollo integral de proyectos de Sistemas de Video vigilancia (CCTV), permite contar con el registro de entrada y salida del personal.</p>
                  <p><Link to="/cctv" className="btn btn-primary px-4 py-3 mt-3">Ver más</Link></p>
                </div>

                <div className="tab-pane fade py-5 text-justify" id="v-pills-5" role="tabpanel" aria-labelledby="v-pills-5-tab">
                  <MdContactPhone color="#0075c7" className="mb-3 d-block" size="4.5em" />
                  <h2 className="mb-4">Consultoría tecnológica</h2>
                  <p>Durante la etapa de planificación del funcionamiento de una empresa, es muy importante realizar la adecuada selección de los sistemas de apoyo administrativos y de gestión. Para ello te asesoramos en ese proceso.</p>
                  <p><Link to="/consultancy" className="btn btn-primary px-4 py-3 mt-3">Ver más</Link></p>
                </div>

                <div className="tab-pane fade py-5 text-justify" id="v-pills-6" role="tabpanel" aria-labelledby="v-pills-6-tab">
                  <MdWifi color="#0075c7" className="mb-3 d-block" size="4.5em" />
                  <h2 className="mb-4">Proveedor WISP</h2>
                  <p>Proveedor de servicios de internet inalámbrico estable y seguro para el área residencial y empresarial, con los mejores marcas y equipos del mercado.</p>
                  <p><Link to="/provider-wisp" className="btn btn-primary px-4 py-3 mt-3">Ver más</Link></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ftco-section-2 img" style={{backgroundImage: `url(${data.bg_3.childImageSharp.fluid.src})`}}>
        <div className="container">
          <div className="row d-md-flex justify-content-end">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12" data-sal="slide-left" data-sal-duration="500" data-sal-easing="ease">
                  <a href="/#" className="services-wrap" >
                    <div className="icon d-flex justify-content-center align-items-center">
                      <span className="ion-ios-arrow-back"></span>
                      <span className="ion-ios-arrow-forward"></span>
                    </div>
                    <h2>Personal Capacitado</h2>
                    <p>Contamos con personal dedicado en realizar los procesos con profesionalismo.</p>
                  </a>
                  <a href="/#" className="services-wrap" >
                    <div className="icon d-flex justify-content-center align-items-center">
                      <span className="ion-ios-arrow-back"></span>
                      <span className="ion-ios-arrow-forward"></span>
                    </div>
                    <h2>Preparación continua</h2>
                    <p>Pro-activos y la vanguardia tecnológica.</p>
                  </a>
                  <a href="/#" className="services-wrap" >
                    <div className="icon d-flex justify-content-center align-items-center">
                      <span className="ion-ios-arrow-back"></span>
                      <span className="ion-ios-arrow-forward"></span>
                    </div>
                    <h2>Últimas tecnologías en Desarrollo </h2>
                    <p>Usamos el ecosistema de React, unas de las mejores actualmente.</p>
                  </a>
                  <a href="/#" className="services-wrap">
                    <div className="icon d-flex justify-content-center align-items-center">
                      <span className="ion-ios-arrow-back"></span>
                      <span className="ion-ios-arrow-forward"></span>
                    </div>
                    <h2>Mejores herramientas</h2>
                    <p>Optámos por usar las mejeores herramientas para proveer un servicio de calidad.</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="ftco-counter img ftco-section ftco-no-pt ftco-no-pb mt-5" id="about-section">
            <div className="container">
                <div className="row d-flex">
                    <div className="col-md-6 col-lg-5 d-flex">
                        <div className="img d-flex align-self-stretch align-items-center" style={{backgroundImage: `url(${data.about.childImageSharp.fluid.src})`}}>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-7 pl-lg-5 py-5">
                        <div className="py-md-5">
                            <div className="row justify-content-start pb-3" data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease">
                                <div className="col-md-12 heading-section">
                                    <span className="subheading">Metalinks</span>
                                    <h2 className="mb-4" style={{fontSize: '34px', textTransform: 'capitalize'}}>¡Creando Enlaces Estables!</h2>
                                    <div className="text-justify">
                                        <p>Metalinks C.A. es un proyecto empresarial, que tiene como principales objetivos, satisfacer la demanda de bienes y servicios con los mayores estándares calidad en cuanto a tecnología, telecomunicaciones y seguridad se refiere.</p>
                                        <p>Nos especializamos en el desarrollo de software a la medida, venta de bienes y servicios de computación, instalación de redes LAN y CCTV, recargas y regeneración de cartuchos de inyección a tinta y laser, electrónica y redes, proveedor wisp (Proveedor de servicios de internet inalámbrico), internet residencial y empresarial, Telefonía VoIP.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="counter-wrap  d-flex mt-md-3">
                                <div className="text p-4 bg-primary" data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease">
                                    <p className="mb-0">
                                    <span className="number" data-number="4">4</span>
                                    <span>Años de experiencia</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="ftco-section">
            <div className="container">
                <div className="row justify-content-center pb-1">
                    <div className="col-md-6 heading-section text-center " data-sal="fade" data-sal-duration="1500" data-sal-easing="ease">
                        <span className="subheading">Nosotros</span>
                        <h2 className="mb-4">Nuestro equipo</h2>
                        <p>Personal dedicado y  capacitado</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-lg-3 ">
                        <div className="staff" data-sal="slide-right" data-sal-duration="500" data-sal-easing="ease">
                            <div className="img-wrap d-flex align-items-stretch">
                                <div className="img align-self-stretch" style={{backgroundImage: `url(${data.colmenares.childImageSharp.fluid.src})`}}></div>
                            </div>
                            <div className="text d-flex align-items-center pt-3 text-center">
                                <div >
                                    <h3 className="mb-2">Christian C.</h3>
                                    <span className="position mb-4">Desarrollador full-stack</span>
                                    <div className="faded" data-sal="slide-up" data-sal-duration="500" data-sal-easing="ease">
                                        <ul className="ftco-social text-center" >
                                            <li className=""><a href="https://www.facebook.com/christian.colmenares.39948" target="_blank"><RiFacebookLine color="#000" size="1.7em" /></a></li>
                                            <li className=""><a href="https://www.instagram.com/christian_colmenares_96/" target="_blank"><RiInstagramLine color="#000" size="1.7em" /></a></li>
                                            <li className=""><a href="https://www.linkedin.com/in/christian-colmenares-3323a1125/" target="_blank"><RiLinkedinLine color="#000" size="1.7em" /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 ">
                        <div className="staff" data-sal="slide-right" data-sal-duration="1000" data-sal-easing="ease">
                            <div className="img-wrap d-flex align-items-stretch">
                                <div className="img align-self-stretch" style={{backgroundImage: `url(${data.vargas.childImageSharp.fluid.src})`}}></div>
                            </div>
                            <div className="text d-flex align-items-center pt-3 text-center">
                                <div>
                                    <h3 className="mb-2">Robinson V.</h3>
                                    <span className="position mb-4">Desarrollador full-stack</span>
                                    <div className="faded" data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                                        <ul className="ftco-social text-center" >
                                            <li className=""><a href="https://www.facebook.com/Robinson0594" target="_blank"><RiFacebookLine color="#000" size="1.7em" /></a></li>
                                            <li className=""><a href="https://www.instagram.com/robinsonvarg/?hl=es-la" target="_blank"><RiInstagramLine color="#000" size="1.7em" /></a></li>
                                            <li className=""><a href="https://www.linkedin.com/in/robinson-vargas/" target="_blank"><RiLinkedinLine color="#000" size="1.7em" /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 ">
                        <div className="staff" data-sal="slide-right" data-sal-duration="1500" data-sal-easing="ease">
                            <div className="img-wrap d-flex align-items-stretch">
                                <div className="img align-self-stretch" style={{backgroundImage: `url(${data.gomes.childImageSharp.fluid.src})`}}></div>
                            </div>
                            <div className="text d-flex align-items-center pt-3 text-center">
                                <div>
                                    <h3 className="mb-2">Victor F.</h3>
                                    <span className="position mb-4">Analista de sistemas </span>
                                    <div className="faded" data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease">
                                        <ul className="ftco-social text-center" >
                                            <li className=""><a href="https://www.facebook.com/victor.figueira.31" target="_blank"><RiFacebookLine color="#000" size="1.7em" /></a></li>
                                            <li className=""><a href="https://www.instagram.com/victorfg26/" target="_blank"><RiInstagramLine color="#000" size="1.7em" /></a></li>
                                            <li className=""><a href="https://www.linkedin.com/in/victor-figueira-gomes-247b11144/" target="_blank"><RiLinkedinLine color="#000" size="1.7em" /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 ">
                        <div className="staff" data-sal="slide-right" data-sal-duration="2000" data-sal-easing="ease">
                            <div className="img-wrap d-flex align-items-stretch">
                                <div className="img align-self-stretch" style={{backgroundImage: `url(${data.manuel.childImageSharp.fluid.src})`}}></div>
                            </div>
                            <div className="text d-flex align-items-center pt-3 text-center">
                                <div>
                                    <h3 className="mb-2">Manuel G.</h3>
                                    <span className="position mb-4">CEO </span>
                                    <div className="faded" data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease">
                                        <ul className="ftco-social text-center" >
                                            <li className=""><a href="/#" target="_blank"><RiFacebookLine color="#000" size="1.7em" /></a></li>
                                            <li className=""><a href="/#" target="_blank"><RiInstagramLine color="#000" size="1.7em" /></a></li>
                                            <li className=""><a href="/#" target="_blank"><RiLinkedinLine color="#000" size="1.7em" /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

      <section className="ftco-section ftco-project bg-light" id="projects-section">
        <div className="container px-md-5">
          <div className="row justify-content-center pb-5">
            <div className="col-md-12 heading-section text-center" data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease">
              <span className="subheading">Proyectos</span>
              <h2 className="mb-4">Nuestros Proyectos</h2>
              <p>Innovación, Desarrollo y Optimización, es lo que define y diferencia nuestros servicios.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 testimonial">
              <div className="carousel-project owl-carousel">
                <OwlCarousel
                    className="owl-theme"
                    loop={true}
                    margin={20}
                    nav={true}
                    items={numCard}
                    autoplay={true}
                    mouseDrag={false}
                    autoplayHoverPause={true}
                  >

                  <div className="item">
                    <div className="project">
                      <div className="img">
                        <img src={`${data.tahrca.childImageSharp.fluid.src}`} className="img-fluid" alt="Metalinks c.a" style={{maxHeight: '328px'}} />
                        <div className="text px-4">
                          <h3><a target="_blank" href="https://tahrca.metalinks.com.ve">Tahrca.com.ve</a></h3>
                          <span>Página corporativa</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div className="project">
                      <div className="img">
                        <img src={`${data.miguel.childImageSharp.fluid.src}`} className="img-fluid" alt="Metalinks c.a" style={{maxHeight: '328px'}}/>
                        <div className="text px-4">
                          <h3><a target="_blank" href="https://metalinks.com.ve/demos/miguel" >Miguel Corporation</a></h3>
                          <span>Página corporativa</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div className="project">
                      <div className="img">
                        <img src={`${data.fullcolor.childImageSharp.fluid.src}`} className="img-fluid" alt="Metalinks c.a" style={{maxHeight: '328px'}}/>
                        <div className="text px-4">
                          <h3><a target="_blank" href="https://metalinks.com.ve/demos/fullcolor" >FullColor C.A</a></h3>
                          <span>Página corporativa</span>
                        </div>
                      </div>
                    </div>
                  </div>

                </OwlCarousel>
              </div>

            </div>
          </div>
        </div>
      </section>


      <section className="ftco-section testimony-section" id="testimony-section">
        <div className="container">
          <div className="row justify-content-center pb-3" data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease">
            <div className="col-md-7 text-center heading-section heading-section-white">
              <h2 className="mb-4">Clientes felices</h2>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="carousel-testimony owl-carousel ftco-owl">
                  <OwlCarousel
                    className="owl-theme"
                    loop={true}
                    margin={40}
                    nav={true}
                    items={1}
                    autoplay={true}
                    mouseDrag={false}
                    autoplayHoverPause={true}
                  >

                  <div className="item">
                    <div className="testimony-wrap text-center py-4 pb-5">
                      <div className="user-img" style={{backgroundImage: `url(${data.no_image.childImageSharp.fluid.src})`}}>
                        <span className="quote d-flex align-items-center justify-content-center">
                          <MdFormatQuote color="#ffffff" size="2.3em"/>
                        </span>
                      </div>
                      <div className="text px-4 pb-5">
                        <p className="mb-4">Excelente atención y servicio, con el mejor personal, de verdad muy feliz con el resultado final de mi nueva Web Corporativa de parte de Metalinks C.A.</p>
                        <p className="name">Ing. Lardys Rodríguez </p>
                        <span className="position">Presidente</span>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div className="testimony-wrap text-center py-4 pb-5">
                      <div className="user-img" style={{backgroundImage: `url(${data.no_image.childImageSharp.fluid.src})`}}>
                        <span className="quote d-flex align-items-center justify-content-center">
                          <MdFormatQuote color="#ffffff" size="2.3em"/>
                        </span>
                      </div>
                      <div className="text px-4 pb-5">
                        <p className="mb-4">Excelente atención y servicio con el mejor personal, de verdad muy feliz con el resultado final de mi nueva Web Corporativa de parte de Metalinks C.A.</p>
                        <p className="name">Lcdo. Michelle Loseto </p>
                        <span className="position">Presidente</span>
                      </div>
                    </div>
                  </div>

                </OwlCarousel>

              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ftco-section bg-light" id="blog-section" style={{padding: '5em 0em 0em 0em'}}>
        <div className="container">
          <div className="row justify-content-center mb-0 pb-5">
            <div className="col-md-7 heading-section text-center" data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease">
              <span className="subheading">Productos</span>
              <h2 className="mb-4">Nuestros productos</h2>
              <p>Sin duda, con el uso de la mejores tecnologías del mercado</p>
            </div>
          </div>
          <div className="row d-flex">



            <div className="col-md-4 d-flex">
              <div className="blog-entry justify-content-end">
                <Link to="/landing-page" className="block-20" style={{backgroundImage: `url(${data.laptop.childImageSharp.fluid.src})`}}>
                </Link>
                <div className="text mt-3 float-right d-block">
                  <div className="d-flex align-items-center pt-2 mb-4 topp">
                    <div className="one mr-0">
                      <span className="day">
                        <MdStar color="#fff" size="0.7em" />
                      </span>
                    </div>
                    {/* <div className="two">
                      <span className="yr">2019</span>
                      <span className="mos">Enero</span>
                    </div> */}
                  </div>
                  <h3 className="heading"><Link to="/landing-page">Landing page</Link></h3>
                  <p>Revoluciona tu negocio y llegas a miles de posibles clientes con una <b>Landing page</b>.</p>
                  <div className="d-flex align-items-center mt-4 meta">
                    <p className="mb-0"><Link to="/landing-page" className="btn btn-primary">Ver más <span className="ion-ios-arrow-round-forward"></span></Link></p>
                    <p className="ml-auto mb-0">
                      <Link to="/landing-page" className="meta-chat"><MdLocalOffer color="#7d7d7d" size="1.5em"/></Link>
                      <Link to="/landing-page" className="mr-2"> web</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex">
              <div className="blog-entry">
                <Link to="/corporate-page" className="block-20" style={{backgroundImage: `url(${data.escritorio_dev.childImageSharp.fluid.src})`}}>
                </Link>
                <div className="text mt-3 float-right d-block">
                  <div className="d-flex align-items-center pt-2 mb-4 topp">
                    <div className="one mr-0">
                      <span className="day">
                          <MdStar color="#fff" size="0.7em" />
                        </span>
                      </div>
                      {/* <div className="two">
                        <span className="yr">2019</span>
                        <span className="mos">Enero</span>
                      </div> */}
                  </div>
                  <h3 className="heading"><Link to="/corporate-page">Página corporativa</Link></h3>
                  <p>No te quedes atrás, impulsa tu marca y hazte notar con una <b>Página corporativa</b> profesional.</p>
                  <div className="d-flex align-items-center mt-4 meta">
                    <p className="mb-0"><Link to="/corporate-page" className="btn btn-primary">Ver más <span className="ion-ios-arrow-round-forward"></span></Link></p>
                    <p className="ml-auto mb-0">
                      <Link to="/corporate-page" className="meta-chat"><MdLocalOffer color="#7d7d7d" size="1.5em"/></Link>
                      <Link to="/corporate-page" className="mr-2"> web</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 d-flex">
              <div className="blog-entry">
                <Link to="/online-shop" className="block-20" style={{backgroundImage: `url(${data.tienda.childImageSharp.fluid.src})`}}>
                </Link>
                <div className="text mt-3 float-right d-block">
                  <div className="d-flex align-items-center pt-2 mb-4 topp">
                    <div className="one mr-0">
                      <span className="day">
                          <MdStar color="#fff" size="0.7em" />
                        </span>
                      </div>
                      {/* <div className="two">
                        <span className="yr">2019</span>
                        <span className="mos">Enero</span>
                      </div> */}
                  </div>
                  <h3 className="heading"><Link to="/online-shop">Tienda virtual</Link></h3>
                  <p>Es un medio para que los clientes puedan adquirir los productos más rápido.</p>
                  <div className="d-flex align-items-center mt-4 meta">
                    <p className="mb-0"><Link to="/online-shop" className="btn btn-primary">Ver más <span className="ion-ios-arrow-round-forward"></span></Link></p>
                    <p className="ml-auto mb-0">
                      <Link to="/online-shop" className="meta-chat"><MdLocalOffer color="#7d7d7d" size="1.5em"/></Link>
                      <Link to="/online-shop" className="mr-2"> web</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      <section className="ftco-section bg-light" id="blog-section" style={{padding: '5em 0em 0em 0em'}}>
        <div className="container">
          <div className="row justify-content-center mb-0 pb-0">
            <div className="col-md-7 heading-section text-center" data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease">
              <span className="subheading">Productos</span>
              <h2 className="mb-4">Tienda Metalinks</h2>
              <p>Sin duda, con el uso de la mejores marcas y equipos del mercado</p>
            </div>
          </div>
          <div className="row d-flex">
            <div className="col-md-4 d-flex">
              <div className="blog-entry justify-content-end">
                <a href="https://tienda.metalinks.com.ve" className="block-20" style={{backgroundImage: `url(${data.cartuchos.childImageSharp.fluid.src})`}}>
                </a>
                <div className="text mt-3 float-right d-block">
                  <div className="d-flex align-items-center pt-2 mb-4 topp">
                    <div className="one mr-0">
                      <span className="day">
                          <MdStar color="#fff" size="0.7em" />
                        </span>
                      </div>
                      {/* <div className="two">
                        <span className="yr">2019</span>
                        <span className="mos">Enero</span>
                      </div> */}
                  </div>
                  <h3 className="heading"><Link to="/#">Cartuchos Toner</Link></h3>
                  <p>Encuentra Cartucho Hp y muchas otras marcas en Tienda Metalinks.</p>
                  <div className="d-flex align-items-center mt-4 meta">
                    <p className="ml-auto mb-0">

                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex">
              <div className="blog-entry justify-content-end">
                <a href="https://tienda.metalinks.com.ve" className="block-20" style={{backgroundImage: `url(${data.mikrotik_prod.childImageSharp.fluid.src})`}}>
                </a>
                <div className="text mt-3 float-right d-block">
                  <div className="d-flex align-items-center pt-2 mb-4 topp">
                    <div className="one mr-0">
                      <span className="day">
                          <MdStar color="#fff" size="0.7em" />
                        </span>
                      </div>
                      {/* <div className="two">
                        <span className="yr">2019</span>
                        <span className="mos">Enero</span>
                      </div> */}
                  </div>
                  <h3 className="heading">Mikrotik</h3>
                  <p>Unas de las mejores marcas a nivel mundial, reconocida por sus productos de alta calidad.</p>
                  <div className="d-flex align-items-center mt-4 meta">
                    <p className="ml-auto mb-0">

                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex">
              <div className="blog-entry">
                <a href="https://tienda.metalinks.com.ve" className="block-20" style={{backgroundImage: `url(${data.tp_link.childImageSharp.fluid.src})`}}>
                </a>
                <div className="text mt-3 float-right d-block">
                  <div className="d-flex align-items-center pt-2 mb-4 topp">
                    <div className="one mr-0">
                      <span className="day">
                          <MdStar color="#fff" size="0.7em" />
                        </span>
                      </div>
                      {/* <div className="two">
                        <span className="yr">2019</span>
                        <span className="mos">Enero</span>
                      </div> */}
                  </div>
                  <h3 className="heading">Router TP-Link</h3>
                  <p>Una de las mejores marcas conocida por su calidad-precio.</p>
                  <div className="d-flex align-items-center mt-4 meta">
                    <p className="ml-auto mb-0">
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="text-center">
                <p><a href="https://tienda.metalinks.com.ve" target="_blank" className="btn btn-primary px-5 py-3 mt-0">Visitar Tienda</a></p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ftco-section bg-light d-none d-xs-none d-md-block" id="blog-section" style={{padding: '5em 0em 1em 0em'}}>
        <div className="container">
            <div className="row justify-content-center mb-0 pb-0">
                <div className="col-md-7 heading-section text-center" data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease">
                    <span className="subheading">Marcas que comercializamos</span>
                    <h2 className="">Marcas</h2>
                </div>
            </div>
            <div className="row justify-content-center mb-2 mt-2">
                <div className="col-sm-4 col-md-1" >
                    <img src={`${data.hp.childImageSharp.fluid.src}`} className="img-fluid" alt="HP"/>
                </div>
                <div className="col-sm-4 col-md-1" >
                    <img src={`${data.intel.childImageSharp.fluid.src}`} className="img-fluid" alt="HP"/>
                </div>
                <div className="col-sm-4 col-md-1" >
                    <img src={`${data.samsung.childImageSharp.fluid.src}`} className="img-fluid" alt="HP"/>
                </div>
                <div className="col-sm-4 col-md-1" >
                    <img src={`${data.nvidia.childImageSharp.fluid.src}`} className="img-fluid" alt="HP"/>
                </div>
                <div className="col-sm-4 col-md-1" >
                    <img src={`${data.tplink.childImageSharp.fluid.src}`} className="img-fluid" alt="HP"/>
                </div>
                <div className="col-sm-4 col-md-1" >
                    <img src={`${data.lg.childImageSharp.fluid.src}`} className="img-fluid" alt="HP"/>
                </div>
                <div className="col-sm-4 col-md-1" >
                    <img src={`${data.acer.childImageSharp.fluid.src}`} className="img-fluid" alt="HP"/>
                </div>
                <div className="col-sm-4 col-md-1" >
                    <img src={`${data.ubiquiti.childImageSharp.fluid.src}`} className="img-fluid" alt="HP"/>
                </div>
                <div className="col-sm-4 col-md-1" >
                    <img src={`${data.TrippLite.childImageSharp.fluid.src}`} className="img-fluid" alt="HP"/>
                </div>
                <div className="col-sm-4 col-md-1" >
                    <img src={`${data.Xerox.childImageSharp.fluid.src}`} className="img-fluid" alt="HP"/>
                </div>
                <div className="col-sm-4 col-md-1" >
                    <img src={`${data.Dahua.childImageSharp.fluid.src}`} className="img-fluid" alt="HP"/>
                </div>

            </div>

            <div className="row justify-content-center mb-2 mt-2">
                <div className="col-sm-4 col-md-1" >
                    <img src={`${data.AMD.childImageSharp.fluid.src}`} className="img-fluid" alt="HP"/>
                </div>
                <div className="col-sm-4 col-md-1" >
                    <img src={`${data.AOC.childImageSharp.fluid.src}`} className="img-fluid" alt="HP"/>
                </div>
                <div className="col-sm-4 col-md-1" >
                    <img src={`${data.Grandstream.childImageSharp.fluid.src}`} className="img-fluid" alt="HP"/>
                </div>
                <div className="col-sm-4 col-md-1" >
                    <img src={`${data.Hikvision.childImageSharp.fluid.src}`} className="img-fluid" alt="HP"/>
                </div>
                <div className="col-sm-4 col-md-1" >
                    <img src={`${data.Iomega.childImageSharp.fluid.src}`} className="img-fluid" alt="HP"/>
                </div>
                <div className="col-sm-4 col-md-1" >
                    <img src={`${data.Mikrotik.childImageSharp.fluid.src}`} className="img-fluid" alt="HP"/>
                </div>
                <div className="col-sm-4 col-md-1" >
                    <img src={`${data.Sandisk.childImageSharp.fluid.src}`} className="img-fluid" alt="HP"/>
                </div>
                <div className="col-sm-4 col-md-1" >
                    <img src={`${data.Toshiba.childImageSharp.fluid.src}`} className="img-fluid" alt="HP"/>
                </div>
                <div className="col-sm-4 col-md-1" >
                    <img src={`${data.Compaq.childImageSharp.fluid.src}`} className="img-fluid" alt="HP"/>
                </div>
                <div className="col-sm-4 col-md-1" >
                    <img src={`${data.Epson.childImageSharp.fluid.src}`} className="img-fluid" alt="HP"/>
                </div>
                <div className="col-sm-4 col-md-1" >
                    <img src={`${data.AOC.childImageSharp.fluid.src}`} className="img-fluid" alt="HP"/>
                </div>

            </div>
        </div>
      </section>

      <section className="ftco-section contact-section ftco-no-pb" id="contact-section" >
        <div className="container">
          <div className="row justify-content-center mb-5 pb-3">
            <div className="col-md-7 heading-section text-center " data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease">
              <span className="subheading">Contacto</span>
              <h2 className="mb-4">Contáctanos</h2>
              <p>Para estar siempre bien informado sobre nuestros productos y servicios</p>
            </div>
          </div>
          <div className="row d-flex contact-info mb-5">
            <div className="col-md-6 col-lg-3 d-flex ">
              <div className="align-self-stretch box p-4 text-center" data-sal="slide-up" data-sal-duration="500" data-sal-easing="ease">
                <div className="icon d-flex align-items-center justify-content-center">
                  <MdLocationOn color="#ffffff" size="2.2em"/>
                </div>
                <h3 className="mb-4">Dirección</h3>
                <p>Av. 28 entre calles 28 y 29 Centro Profesional Padre Pio sector Campo Lindo, Acarigua Edo. Portuguesa.</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 d-flex ">
              <div className="align-self-stretch box p-4 text-center" data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                <div className="icon d-flex align-items-center justify-content-center">
                  <MdPhone color="#ffffff" size="2.2em"/>
                </div>
                <h3 className="mb-4">Número de Contacto</h3>
          <p>
            <a href="tel://4245149117">+ 58 424-5149117 </a><br/>
            <a href="tel://4143559214">+ 58 414-3559214 </a>
          </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 d-flex ">
              <div className="align-self-stretch box p-4 text-center" data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease">
                <div className="icon d-flex align-items-center justify-content-center">
                  <MdEmail color="#ffffff" size="2.2em"/>
                </div>
                <h3 className="mb-4">Correo Electrónico</h3>
                <p><a href="mailto:contacto@metalinks.com.ve">contacto@metalinks.com.ve</a></p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 d-flex ">
              <div className="align-self-stretch box p-4 text-center" data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease">
                <div className="icon d-flex align-items-center justify-content-center">
                  <MdPublic color="#ffffff" size="2.2em"/>
                </div>
                <h3 className="mb-4">Sitio web</h3>
                <p><a href="/#">metalinks.com.ve</a></p>
              </div>
            </div>
          </div>
          <div className="row no-gutters block-9">
            <div className="col-md-6 order-md-last d-flex">
              <form action="https://api-email.metalinks.com.ve/public/api/v1/send/eyJpdiI6Im4zSmVjNGVKK2hHQmVRYzBFd1ZmZ1E9PSIsInZhbHVlIjoiKzRHZVM4cGJKSFpDSnZkaVlYWVNhZz09IiwibWFjIjoiMTFjNDUxMjdmMDQ2NjFiNjAyOWMzYTEyMmFhZjM2NDQ1ZDhkMzNmM2JmNzE3MjU3ZjdiZDJjYWMyMWUxZDgzMiJ9" className="bg-light p-5 contact-form" method="POST">
                <div className="form-group">
                  <input type="text" className="form-control" name="name" placeholder="Nombre" />
                </div>
                <div className="form-group">
                  <input type="text" className="form-control" name="email" placeholder="Correo Electrónico"/>
                </div>
                <div className="form-group">
                  <input type="text" className="form-control" name="subject" placeholder="Asunto"/>
                </div>
                <div className="form-group">
                  <textarea name="message" id="" cols="30" rows="7" className="form-control" placeholder="Mensaje"></textarea>
                </div>
                <div className="form-group">
                  <input type="submit" value="Enviar Mensaje" className="btn btn-primary py-3 px-5"/>
                </div>
              </form>

            </div>

            <div className="col-md-6 d-flex">
        <div id="map">
          <iframe id="maps" title="Mapa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d819.6649139879543!2d-69.20685411194619!3d9.56147219956994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zOcKwMzMnNDEuMyJOIDY5wrAxMicyMi43Ilc!5e1!3m2!1ses!2sve!4v1582119945795!5m2!1ses!2sve" frameborder="0" style={{border:0, width: '100%',height: '100%'}} allowfullscreen></iframe>
          </div>
            </div>
          </div>
        </div>
      </section>

    </Layout>
  )
}


export default IndexPage
